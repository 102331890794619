import { useEffect } from 'react';

import firebase from '../firebase';
import useFirebaseReducer, { DefaultData, FirebaseReducerActions } from './use-firebase-reducer';

const useReviews = (userId, filters, orderBys, limit) => {
    const [ data, dispatch ] = useFirebaseReducer(DefaultData);
    useEffect(() => {
        let unsubscribe = null;

        async function subscribe() {
            unsubscribe = await firebase.reports.reviews.subscribeToReviews(
                userId,
                filters,
                orderBys,
                limit,
                (snapshot) => {
                    dispatch({
                        type: FirebaseReducerActions.Snapshot,
                        data: snapshot,
                        isExisting: !snapshot.empty
                    });
                },
                (error) => {
                    if (error?.code === 'permission-denied')
                        dispatch({ type: FirebaseReducerActions.Forbidden });
                }
            );
        }

        if (userId)
            subscribe();

        return () => {
            if (unsubscribe)
                unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ userId, filters, limit, orderBys ]);

    return data;
};

export default useReviews;
