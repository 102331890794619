import React from 'react';
import Reviews from '../components/reviews';
import InternalLayout from '../layouts/internal';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	}
}));

const ReviewsPage = () => {
	const classes = useStyles();

	return (
		<Paper className={classes.paper}>
			  <Reviews/>
		</Paper>
);
	}


ReviewsPage.getLayout = (page) => (
  <InternalLayout>{page}</InternalLayout>
);

export default ReviewsPage;
