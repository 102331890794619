class AuthErrorCode {
    static CaptchaInitializationFailed = 'auth/captcha-initialization-failed';
    CaptchaCheckFailed = 'auth/captcha-check-failed';
    InvalidPhoneNumber = 'auth/invalid-phone-number';
    MissingPhoneNumber = 'auth/missing-phone-number';
    QuotaExceeded = 'auth/quota-exceeded';
    UserDisabled = 'auth/user-disabled';
    OperationNotAllowed = 'auth/operation-not-allowed';
    InvalidVerificationCode = 'auth/invalid-verification-code';
    MissingVerificationCode = 'auth/missing-verification-code';
    UserNotFound = 'auth/user-not-found';
    WrongPassword = 'auth/wrong-password';
    EmailAlreadyInUse = 'auth/email-already-in-use';
    InvalidEmail = 'auth/invalid-email';
    WeakPassword = 'auth/weak-password';
    MissingAndroidPkgName = 'auth/missing-android-pkg-name';
    MissingContinueUri = 'auth/missing-continue-uri';
    MissingIosBundleId = 'auth/missing-ios-bundle-id';
    InvalidContinueUri = 'auth/invalid-continue-uri';
    UnauthorizedContinueUri = 'auth/unauthorized-continue-uri';
    RequiresRecentLogin = 'auth/requires-recent-login';
    NotLoggedIn = 'auth/not-logged-in';
    TooManySignInAttempts = 'auth/too-many-attempts';
}

export default AuthErrorCode;