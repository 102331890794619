/* eslint-disable no-console */
//import * as Sentry from '@sentry/node';


/**
 * Logging wrapper (WIP)
 */
const logger = {
    log: (...args) => {
        logger.debug(args);
    },
    debug: (message, ...params) => {
        if (process.env.NODE_ENV === 'production')
            return;

        console.log(message, ...params);
    },
    info: (message, ...params) => {
        if (process.env.NODE_ENV === 'production')
            return;

        console.log(message, ...params);
    },
    warn: (message, ...params) => {
        // Should use console.warn but kinda impractical
        console.error(message, ...params);
    },
    error: (message, options, ...params) => {
        /*let error = null;
        let context = {};

        if (options) {
            if (options instanceof Error)
                error = options;
            else if (typeof options === 'string')
                error = new Error(options);
            else {
                if (options.error instanceof Error)
                    error = options.error;
                else if (typeof options.error === 'string')
                    error = new Error(options.error);

                context = options.context || {};
            }
        }

        const referenceCode = utils.getReferenceCode();

        console.error(`${message} [refCode: ${referenceCode}]`, error, ...params);

        const captureContext = {
            contexts: {
                metadata: {
                    clientKey: utils.getClientKey(),
                    referenceCode
                }
            }
        };

        if (typeof window !== 'undefined' && window.localStorage?.getItem) {
            const currentUser = window.localStorage?.getItem('currentUser');

            if (currentUser)
                captureContext.user = typeof currentUser === 'string' ? JSON.parse(currentUser) : currentUser;
        }

        Object.entries(context).forEach(([ key, value ]) => {
            if (!captureContext.contexts.context)
                captureContext.contexts.context = {};

            if (typeof value === 'object')
                captureContext.contexts.context[key] = JSON.stringify(value);
            else
                captureContext.contexts.context[key] = value;
        });

        if (error) {
            captureContext.contexts.metadata = {
                message
            };

            Sentry.captureException(error, captureContext);
        } else
            Sentry.captureException(message, captureContext);

        Sentry.flush(2000).catch((ex) => {
            console.error('Failed to flush sentry', ex);
        });

        return {
            referenceCode
        };*/
    }
};

export default logger;
