import {
    Auth
} from '../sdk';

export default class {
    constructor(firebase) {
        this.firebase = firebase;
    }

    createQrCode = async (qrCodeId,locationId, comment) => {
        if (qrCodeId) {

            const currentUser = await Auth.getCurrentUser();
            if (currentUser && currentUser.uid) {
                return this.firebase.db
                    .collection('qr-codes')
                    .doc(qrCodeId)
                    .set({ id: qrCodeId, owner_id: currentUser.uid, location_id: locationId, comment: comment });
            }
        }

        throw new Error('qrCodeId is required');
    }

    getQrCode = async (qrCodeId) => {
        if (qrCodeId) {
            return this.firebase.db
                .collection('qr-codes')
                .doc(qrCodeId)
                .get();
        }

        return Promise.reject(new Error('Missing parameter "qrCodeId"'));
    }

}


