import React from 'react';
import { BrowserRouter as Router,  Switch } from 'react-router-dom';
import LoginPage from './pages/login';
import ReviewsPage from './pages/reviews';
import ReviewEditPage from './pages/review-edit';
import NewLocationPage from './pages/new-location';
import Route from './components/route';

function App() {
	return (
			<Router>
					<Switch>
						<Route exact path="/" component={ReviewsPage} onlyLoggedInUser/>
						<Route exact path="/login" component={LoginPage} />
						<Route exact path="/reviews/:reviewId" component={ReviewEditPage} onlyLoggedInUser/>
						<Route exact path="/new-location" component={NewLocationPage} onlyLoggedInUser/>
					{/*	<Route exact path="/signup" component={SignupPage} />
						<Route exact path="/reviews" component={ReviewsPage} onlyLoggedInUser/>
						<Route exact path="/send-test" component={SendTestPage}/>
						<Route exact path="/reports" component={ReportsPage} onlyLoggedInUser/>
						<Route exact path="/locations" component={LocationsPage} onlyLoggedInUser/>
						<Route exact path="/locations/:locationId/links" component={LocationEditLinksPage} onlyLoggedInUser/>
	<Route exact path="/locations/:locationId/links/:linkId" component={LocationEditLinkEditPage} onlyLoggedInUser/> */}
					</Switch>
			</Router>
	);
}

export default App;
