/* eslint-disable import/no-unused-modules */
import utils from '../common/utils';
import { Lavizor } from '../config';

class UserManagementModule {
    /**
     * Creates a new user
     *
     * @returns Returns void
     */
    async createUser(user) {
        return await Lavizor.getFirebase().firestore().doc(`users/${user.user_id}`).set({
            user_id: user.user_id
        }, { merge: true });
    }

    /**
     * Gets a user based on the uid
     *
     * @returns Returns the user object
     */
    async getUser(uid) {
        const docRef = await Lavizor.getFirebase().firestore().doc(`users/${uid}`).get();
        const doc = docRef.data();
        const result = utils.convertUserDocToLavizorUser(doc);

        return result;
    }
}

export const UserManagement = new UserManagementModule();

export { default as UserManagementErrorCode } from './error-code';

export { default as User } from './user';

export * from './profiles';