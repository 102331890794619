export default class {
    constructor(firebase) {
        this.firebase = firebase;
    }

    getUser = async (userId) => {
        if (userId) {
            return this.firebase.db
                .collection('users')
                .doc(userId)
                .get();
        }

        return Promise.reject(new Error('Missing parameter "userId"'));
    }

    subscribeToUser = async (userId, successCallback, errorCallback) => this.firebase.db
        .collection('users')
        .doc(userId)
        .onSnapshot(successCallback, (error) => {
            console.log(error, errorCallback);
            //logger.error('Failed in subscribeToUser', error);

          //  errorCallback?.(error);
        });

    updateUser = (userId, data) => {
        if (userId) {
            return this.firebase.db
                .collection('users')
                .doc(userId)
                .set({ ...data }, { merge: true });
        }

        throw new Error('userId is required');
    }
}
