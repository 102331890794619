

import {LavizorError, ErrorCode} from '../sdk/errors';

import {
    Auth
} from '../sdk';
import Links from './links';
import Public from './public';

export default class {
    constructor(firebase) {
        this.firebase = firebase;

        this.Links = new Links(this.firebase);
        this.Public = new Public(this.firebase);
    }

    get links() {
        return this.Links;
    }

    get public() {
        return this.Public;
    }

    createLocation = async (locationId) => {
        if (locationId) {

            const currentUser = await Auth.getCurrentUser();
            if (currentUser && currentUser.uid) {
                return this.firebase.db
                    .collection('locations')
                    .doc(locationId)
                    .set({ id: locationId, owner_id: currentUser.uid });
            }
        }

        throw new Error('userId is required');
    }
    subscribeToLocations = async (userId, filters, orderBys, limit, successCallback, errorCallback) => {
        let query = this.firebase.db
        .collection('locations')
        .where('owner_id','==', userId);

        if (filters) {
            filters.forEach((filter) => {
                query = query.where(...filter);
            });
        }

        if (orderBys && orderBys.length > 0) {
            orderBys.forEach((orderBy) => {
                query = query.orderBy(...orderBy);
            });
        }// else
         //   query = query.orderBy('created_at', 'asc');
        
        if (typeof limit === 'number')
            query = query.limit(limit);

        const unsubscribe = query.onSnapshot(
            (data) => {
                const result = data.docs.map((doc) => doc.data());
                if (successCallback)
                    successCallback(result || []);
            },
            (error) => {
                if (errorCallback) {
                    const generatedError = LavizorError.translateFirestoreError(
                        error,
                        new LavizorError('Failed to fetch reports', ErrorCode.DatabaseOperationError, error)
                    );

                    errorCallback(generatedError);
                }
            }
        );

        return unsubscribe;
    }


}
