import { Lavizor } from './sdk';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

import Users from './users';
import Reports from './reports';
import Locations from './locations';
import QrCodes from './qr-codes';

class Firebase {
    constructor() {

        this.firebase = {
            internal: Lavizor.getFirebase(),
            auth: Lavizor.getFirebase().auth(),
            db: Lavizor.getFirebase().firestore(),
            functions: Lavizor.getFirebase().functions(),
            storage: Lavizor.getFirebase().storage()
        };

        this.Users = new Users(this.firebase);
        this.Reports = new Reports(this.firebase);
        this.Locations = new Locations(this.firebase);
        this.QrCodes = new QrCodes(this.firebase);
    }


    get users() {
        return this.Users;
    }

    get reports() {
        return this.Reports;
    }

    get locations() {
        return this.Locations;
    }

    get qrCodes() {
        return this.QrCodes;
    }
}

export default new Firebase();
