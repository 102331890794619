import { extension } from 'mime-types';
import { customAlphabet } from 'nanoid';

import { UserManagement } from '../user-management';
import User from '../user-management/user';

const utils = {
    isFunction: (fn) => typeof fn === 'function', // eslint-disable-line
    generateRandomString: (length, alphabet) => {
        const actualAlphabet = alphabet || 'abcdefghijklmnopqrstuvwxyz1234567890';
        const nanoid = customAlphabet(actualAlphabet, length);
        const result = nanoid();

        return result;
    },
    generateFileName: ({ prefix, contentType }) => {
        const randomString = utils.generateRandomString(24);
        const result = `${prefix || ''}${randomString}${contentType ? `.${extension(contentType)}` : ''}`;

        return result;
    },
    convertFromFirebaseUser: async (user) => {
        const result = user ? await UserManagement.getUser(user.uid) : null;

        return result;
    },
    convertFirebaseUserToLavizorUser: (data) => {
        if (!data)
            return null;

        const userObject = {
            uid: data.uid,
            name: data.displayName,
            phone_number: data.phoneNumber,
            email_address: data.email
        };
    
        const user = new User(userObject);
    
        return user;
    },
    convertUserDocToLavizorUser: (data) => {
        if (!data)
            return null;

        const userObject = {
            uid: data.user_id,
            name: data.name,
            phone_number: data.phone_number,
            email_address: data.email_address
        };
    
        const user = new User(userObject);

        if (data.shipping_details)
            user._shipping_address = data.shipping_details;
    
        return user;
    }
};

export default utils;