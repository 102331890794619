import React from 'react';
import { makeStyles } from '@material-ui/core/styles';




const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        position: 'fixed',
        left: 0,
        bottom: '10px',
        width: '100%',
        zIndex: '1070',
    },
    wrap: {
        maxWidth: '1200px',
        paddingRight: '1rem',
        paddingLeft: '1rem',
        marginRight: 'auto',
        marginLeft: 'auto',
        width: '100%',
        boxSizing: 'border-box'
    },
    inner: {
        position: 'relative',
        padding: '.875rem 1rem 1.125rem',
        borderRadius: '.3125rem',
        background: '#fff',
        boxShadow: '0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%)'
    },
    title: {
        textAlign: 'left',
        marginBottom: '10px',
        paddingRight: '20px',
        fontSize: '14px',
        fontWeight: 600,
        color: '#0092DD'
    },
    description: {
        display: 'block',
        fontSize: '14px'
    },
    buttonClose: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        background: 'none',
        border: 'none',
        padding: '5px'
    },
    exportIcon: {
        width: '15px',
        height: '15px',
        margin: '0 5px'
    },
    closeIcon: {
        width: '10px',
    }
  }));

const isInStandaloneMode = () =>
  (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
const isMobile = () => /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ;

const InstallApplication = () => {
	const classes = useStyles();
    if (isInStandaloneMode() || !isMobile()) {
        return <></>;
    }
	return (<div>
        <div className={classes.root}>
            <div className={classes.wrap}>
                <div className={classes.inner}>
                    <div className={classes.title}>
                        Установить приложение "Лавизор"
                    </div>
                    <div className={classes.description}>
                        Нажмите на значок
                        <svg className={classes.exportIcon} enableBackground="new 0 0 1000 1000" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg"><path d="m780 290h-140v35h140c19.3 0 35 15.7 35 35v560c0 19.3-15.7 35-35 35h-560c-19.2 0-35-15.7-35-35v-560c0-19.2 15.7-35 35-35h140v-35h-140c-38.7 0-70 31.3-70 70v560c0 38.7 31.3 70 70 70h560c38.7 0 70-31.3 70-70v-560c0-38.7-31.3-70-70-70zm-407.5-110 110-110.2v552.7c0 9.6 7.9 17.5 17.5 17.5s17.5-7.9 17.5-17.5v-552.7l110 110c3.5 3.5 7.9 5 12.5 5s9-1.7 12.5-5c6.8-6.8 6.8-17.9 0-24.7l-140-140c-6.8-6.8-17.9-6.8-24.7 0l-140 140c-6.8 6.8-6.8 17.9 0 24.7 6.7 7 17.7 7 24.7.2z"/></svg>
                        и выберите "Добавить на главный экран"
                    </div>
                </div>
            </div>
        </div>
    </div>);
};


export default InstallApplication;
