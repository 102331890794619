/* eslint-disable import/no-unused-modules */
/* eslint-disable no-unused-expressions */

import utils from '../common/utils';
import { Lavizor } from '../config';
import { ErrorCode, LavizorError } from '../errors';
import { UserManagement } from '../user-management';

import AuthErrorCode from './error-code';

export const initializeRecaptcha = () => {
    let recaptchaVerifier = null;

    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const windowObj = window;
        const index = (windowObj.recaptchaIndex || 0) + 1;
        const firebaseContainerId = `firebase-recaptcha-${index}`;
        const div = document.createElement('div');
        const recaptchaContainer = Lavizor.getConfig().recaptchaContainer;

        windowObj.recaptchaIndex = index;
        div.setAttribute('id', firebaseContainerId);
        document.querySelector(recaptchaContainer || 'body')?.appendChild(div);

        try {
            const RecaptchaVerifier = Lavizor.getRecaptchaVerifier();

            recaptchaVerifier = new RecaptchaVerifier(
                firebaseContainerId,
                {
                    size: 'invisible',
                    callback: () => {}
                }
            );
        } catch (ex) {
            throw new LavizorError('Failed to initialize reCAPTCHA', AuthErrorCode.CaptchaInitializationFailed);
        }
    }

    return recaptchaVerifier;
};

class AuthModule {

    /**
     * Allows login using email and password.
     */
    async signInWithEmailAndPassword(email, password) {
        const result = new Promise((resolve, reject) => {
            // We have to use Promise here due to bug in Firebase SDK: https://github.com/firebase/firebase-js-sdk/issues/1881
            Lavizor.getFirebase().auth().signInWithEmailAndPassword(email, password)
                .then((userCredential) => {
                    utils.convertFromFirebaseUser(userCredential?.user)
                        .then((user) => {
                            if (!user)
                                reject(new LavizorError('Failed to fetch user after sign in', ErrorCode.UnknownError));

                            resolve(user);
                        })
                        .catch((ex) => {
                            reject(LavizorError.translateFirestoreError(ex, 'Failed to fetch user after sign in'))
                        });
                })
                .catch((ex) => {
                    reject(LavizorError.translateFirestoreError(ex, 'Failed to sign in'))
                });
        });

        return result;
    }

    /**
     * Create a new user using an email, password, and other required attributes
     */
    async createUserWithEmailAndPassword(emailAddress, password, userDetails) {
        return new Promise((resolve, reject) => Lavizor.getFirebase().auth().createUserWithEmailAndPassword(emailAddress, password)
            .then(async (userCredential) => {
                const newUser = {
                    user_id: userCredential.user?.uid || ''
                };

                await UserManagement.createUser(newUser);

                const currentUser = await Auth.getCurrentUser();

                if (currentUser) {
                    await currentUser.updateEmailAddress(emailAddress);
                    await currentUser.updateName(userDetails.name);
                }

                resolve(currentUser);
            })
            .catch((error) => reject(error)));
    }

    /**
     * Sends email verification to currently signed-in user
     */
    async sendEmailVerification() {
        throw new LavizorError('Not implemented', ErrorCode.NotImplementedError);
    }

    /**
     *
     */
    async sendPasswordResetEmail(emailAddress) {
        try {
            await Lavizor.getFirebase().auth().sendPasswordResetEmail(emailAddress);
        } catch (error) {
            throw LavizorError.translateFirestoreError(error, new LavizorError('Failed to send the reset password email', ErrorCode.UnknownError));
        }
    }

    /**
     * Signs out the currently signed-in user
     *
     * @throws {@link LavizorError} (code: {@link AuthErrorCode.NotLoggedIn}) Thrown if there is no logged in user.
     */
    async signOut() {
        try {
            await Lavizor.getFirebase().auth().signOut();
        } catch (error) {
            throw LavizorError.translateFirestoreError(error, new LavizorError('Failed to sign out the user', ErrorCode.UnknownError));
        }
    }

    /**
     * Gets the current logged in user
     *
     * @returns Returns the user object if logged in, otherwise null.
     */
    async getCurrentUser() {
        const currentUser = Lavizor.getFirebase().auth().currentUser;

        if (!currentUser?.uid)
            return null;

        let user = await UserManagement.getUser(currentUser?.uid);

        if (!user)
            user = utils.convertFirebaseUserToFluxUser(currentUser);


        return user;
    }

    /**
     * Allows subscription to changes in the state of user login
     *
     * @param callback The method called whenever auth state has changed
     * @returns A method that can be called to unsubscribe from this event
     */
    subscribeToAuth(callback, errorCallback) {
        let unsubscribeFromAuthStateChange = null;
        let unsubscribeUserSnapshot = null;

        unsubscribeFromAuthStateChange = Lavizor.getFirebase().auth().onAuthStateChanged(
            (user) => {
                if (user) {
                    unsubscribeUserSnapshot = Lavizor.getFirebase().firestore().doc(`users/${user.uid}`).onSnapshot(
                        (data) => {
                            const result = utils.convertUserDocToLavizorUser(data?.data());

                            if (result)
                                callback(result);
                        },
                        (snapshotError) => {
                            if (errorCallback) {
                                errorCallback(LavizorError.translateFirestoreError(
                                    snapshotError, new LavizorError('Failed to get user data from snapshot subscription', ErrorCode.DatabaseOperationError)
                                ));
                            }
                        }
                    )
                } else { // This means the user has logged out
                    if (unsubscribeUserSnapshot)
                        unsubscribeUserSnapshot()

                    unsubscribeUserSnapshot = null;
                    callback(null);
                }
            },
            (stateChangeError) => {
                if (errorCallback) {
                    errorCallback(LavizorError.translateFirestoreError(
                        stateChangeError, new LavizorError('Failed to subscribe to authentication state change', ErrorCode.DatabaseOperationError)
                    ));
                }
            }
        )

        const unsubscribe = () => {
            if (unsubscribeUserSnapshot)
                unsubscribeUserSnapshot();

            if (unsubscribeFromAuthStateChange)
                unsubscribeFromAuthStateChange();

            unsubscribeUserSnapshot = null;
            unsubscribeFromAuthStateChange = null;
        };

        return unsubscribe;
    }
}

const Auth = new AuthModule();

export default Auth;