import React from 'react';
import InternalLayout from '../layouts/internal';
import { useParams } from "react-router-dom";
import ReviewEditForm from '../components/forms/review-edit-form';



const ReviewEditPage = () => {

	const { reviewId } = useParams();
	return (
	<div>
		<ReviewEditForm reviewId={reviewId}/>
	</div>
	)
	};

ReviewEditPage.getLayout = (page) => (
	<InternalLayout goBack>{page}</InternalLayout>
);

export default ReviewEditPage;
