

import {LavizorError, ErrorCode} from '../sdk/errors';

export default class {
    constructor(firebase) {
        this.firebase = firebase;
    }

    subscribeToReviews = async (userId, filters, orderBys, limit, successCallback, errorCallback) => {
        let query = this.firebase.db
        .collection('reports')
        .doc(userId)
        .collection('reviews');

        if (filters) {
            filters.forEach((filter) => {
                query = query.where(...filter);
            });
        }

        if (orderBys && orderBys.length > 0) {
            orderBys.forEach((orderBy) => {
                query = query.orderBy(...orderBy);
            });
        }// else
         //   query = query.orderBy('created_at', 'asc');
        
        if (typeof limit === 'number')
            query = query.limit(limit);

        const unsubscribe = query.onSnapshot(
            (data) => {
                const result = data.docs.map((doc) => {
                    return {...doc.data(), id:doc.id}});
                if (successCallback)
                    successCallback(result || []);
            },
            (error) => {
                if (errorCallback) {
                    const generatedError = LavizorError.translateFirestoreError(
                        error,
                        new LavizorError('Failed to fetch reports', ErrorCode.DatabaseOperationError, error)
                    );

                    errorCallback(generatedError);
                }
            }
        );

        return unsubscribe;
    }

    getReview = async (userId, reviewId, callback, errorCallback) => {
        let review = this.firebase.db
        .collection('reports')
        .doc(userId)
        .collection('reviews')
        .doc(reviewId).onSnapshot(
            (data) => {
                const result = data?.data();

                if (result)
                    callback(result);
            },
            (snapshotError) => {
                if (errorCallback) {
                    errorCallback(LavizorError.translateFirestoreError(
                        snapshotError, new LavizorError('Failed to get review data from snapshot subscription', ErrorCode.DatabaseOperationError)
                    ));
                }
            }
        )
        return review;
    }

    updateReview = (userId, reviewId, data) => {
        if (reviewId) {
            return this.firebase.db
                .collection('reports')
                .doc(userId)
                .collection('reviews')
                .doc(reviewId)
                .set({ ...data }, { merge: true });
        }

        throw new Error('userId is required');
    }
}