import { Auth, AuthErrorCode } from '../../core/firebase/sdk';
import { FORM_ERROR } from 'final-form';
import { useState } from 'react';
import { Form as ReactForm, Field } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { logger, regexp } from '../../core/helpers';
import { makeStyles } from '@material-ui/core/styles';


	/*customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	}*/

	const useStyles = makeStyles((theme) => ({
		paper: {
		  marginTop: theme.spacing(8),
		  display: 'flex',
		  flexDirection: 'column',
		  alignItems: 'center',
		},
		avatar: {
		  margin: theme.spacing(1),
		  backgroundColor: theme.palette.secondary.main,
		},
		form: {
		  width: '100%', // Fix IE 11 issue.
		  marginTop: theme.spacing(1),
		},
		submit: {
		  margin: theme.spacing(3, 0, 2),
		},
	  }));

const LoginForm = () => {
	const [ isLoading, setIsLoading ] = useState();
    const history = useHistory();
	const classes = useStyles();
	
    return (
        <ReactForm
            validate={LoginForm.validate}
            // eslint-disable-next-line react/jsx-sort-props
            onSubmit={(values) => LoginForm.onSubmit(values, setIsLoading, history)}
            render={({ handleSubmit, submitError }) => {			
				return (
                <form onSubmit={handleSubmit} noValidate>
                    {submitError && (
						<Typography variant="body2">
						{submitError}
					</Typography>
                    )}
						<Grid container spacing={2}>

							<Grid item xs={12}>
								<Field name='email' type='text'>
									{({ input, meta }) => (
										<TextField
											{...input}
											variant="outlined"
											required
											fullWidth
											id="email"
											label="Email"
											name="email"
											autoComplete="email"
											error={meta.submitFailed}
										/>
									)}
								</Field>
							</Grid>

							<Grid item xs={12}>
								<Field name='password' type='text'>
									{({ input, meta }) => (
										<TextField
											{...input}
											variant="outlined"
											required
											fullWidth
											name="password"
											label="Пароль"
											type="password"
											id="password"
											autoComplete="current-password"
											error={meta.submitFailed}
										/>
									)}
								</Field>
							</Grid>
						</Grid>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
                            disabled={isLoading}
							className={classes.submit}
						>
							Войти
							{isLoading && <CircularProgress size={30} />}
						</Button>
					</form>

            )}
									}
        />
    );
};


LoginForm.onSubmit = async (values, setIsLoading, history) => {
    try {
        setIsLoading(true);

        await Auth.signInWithEmailAndPassword(values.email, values.password);
		window.location = '/';

        return null;
    } catch (ex) {
        setIsLoading(false);

        logger.error('Error submitting form:', ex);

        const errors = {};

        switch (ex.code) {
            case AuthErrorCode.InvalidEmail:
            case AuthErrorCode.EmailAlreadyInUse:
                errors.email_address = ex.message;
                break;

            case AuthErrorCode.WeakPassword:
                errors.password = ex.message;
                break;

            case AuthErrorCode.UserNotFound:
            case AuthErrorCode.WrongPassword:
                errors[FORM_ERROR] = 'The email address and password combination is wrong.';
                break;

            default:
                errors[FORM_ERROR] = ex.message;
        }

        return errors;
    }
};

LoginForm.validate = (values) => {
    const errors = {};

    if (!values.email)
        errors.email = 'Email address is required';

    if (!values.password)
        errors.password = 'Password is required';

    if (values.email && values.email.match(regexp.EmailRegexp) == null)
        errors.email = 'Please enter a valid email address';

    return errors;
};

export default LoginForm;
