
import firebase from 'firebase/app';
import cloneDeep from 'lodash/cloneDeep';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import { ErrorCode, LavizorError } from '../errors';

const defaultConfig = {
    version: '1',
    recaptchaContainer: 'body',
    firebase: {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID
    }
};


class Lavizor {
    config = defaultConfig;
    firebase = null;

    getConfig() {
        const result = cloneDeep(this.config);
        
        return result;
    }

    /**
     * Returns a preconfigured instance of firebase for cloud operations
     *
     */
    getFirebase = () => {
        if (!this.firebase && this.config?.firebase && !firebase.apps.length)
            this.firebase = firebase.initializeApp(this.config.firebase);
        
        if (!this.firebase)
            throw new LavizorError('Firebase is not initialized', ErrorCode.InvalidOperationError);

        return this.firebase;
    };

    runBatch = async (callback) => {
        const batch = this.getFirebase().firestore().batch();

        callback({ batch });

        batch.commit();
    };

    runTransaction = async (callback) => {
        await this.getFirebase().firestore().runTransaction(async (transaction) => {
            await callback({ transaction });
        });
    };

    getTimestampFieldValue = () => firebase.firestore.FieldValue.serverTimestamp();

    /**
     * A temporary solution to use RecaptchaVerifier from the same firebase instnace used by the SDK.
     * This will be removed once the Auth module is fully implemented.
     *
     * @returns The RecaptchaVerifier for use in phone authentication
     */
    getRecaptchaVerifier() {
        return firebase.auth.RecaptchaVerifier;
    }
}

export default new Lavizor();
