import {
    Auth,
    ErrorCode,
    LavizorError
} from '../firebase/sdk';
import { useEffect } from 'react';

import useFirebaseReducer, { FirebaseReducerActions } from './use-firebase-reducer';

const useAuth = ()=> {
    const [ data, dispatch ] = useFirebaseReducer();
    useEffect(() => {
        let unsubscribe = null;

        async function subscribe() {
            unsubscribe = Auth.subscribeToAuth((data) => {
                dispatch({
                    type: FirebaseReducerActions.Snapshot,
                    data,
                    isExisting: true
                });
            }, (error) => { 
                if (error?.code === 'permission-denied')
                    dispatch({ type: FirebaseReducerActions.Forbidden });
                else {
                    dispatch({
                        type: FirebaseReducerActions.Error,
                        error: LavizorError.translateFirestoreError(
                            error,
                            new LavizorError('Failed to subscribe to auth', ErrorCode.DatabaseOperationError)
                        )
                    });
                }
            });
        }

        subscribe();

        return () => {
            if (unsubscribe)
                unsubscribe();
        };
    }, []);

    return data;
};

export default useAuth;