import { AuthErrorCode } from '../../core/firebase/sdk';
import { FORM_ERROR } from 'final-form';
import { useState } from 'react';
import { Form as ReactForm, Field } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import firebase from '../../core/firebase'; 
import QrReader from 'react-qr-reader'

import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { logger } from '../../core/helpers';
import { useLocations, useAuth } from '../../core/hooks';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
	qrCodeError: {
		color: 'red',
        fontSize: '12px',
        marginTop: '10px',
        textAlign: 'center'
	},
    qrCodeWrapper: {
        display: 'flex'
    },
    qrCodeTextField: {
        flexGrow: '1'
    },
    refreshIcon: {
        
    }
}));
const NewLocationForm =  () => {
	const classes = useStyles();
	const [ isLoading, setIsLoading ] = useState();
	const [ qrCode, setQrCode ] = useState();
	const [ qrCodeError, setQrCodeError ] = useState();
    const history = useHistory();
    
    const { data: user } = useAuth() || {};
    const locations = useLocations(user?.uid);

    const initialValues = {
        id: qrCode,
        location_id: locations && locations.data? locations.data[0].id : null,
    };

    const handleScan = async (data) => {
        if (data) {
            if (data.match(/^(https:\/\/lvzr\.ru\/qr\/)(.*)/) &&
                data.match(/^(https:\/\/lvzr\.ru\/qr\/)(.*)/)[2]) 
            {
                const qrCodeId= data.match(/^(https:\/\/lvzr\.ru\/qr\/)(.*)/)[2];
                const existingQrCode = await firebase.qrCodes.getQrCode(qrCodeId);
                if (existingQrCode?.data()) {
                    setQrCodeError('QR код уже активирован');
                }
                else {
                    setQrCode(qrCodeId)
                    setQrCodeError(null);
                }
                
            }
            else {
                setQrCodeError('QR код некорректный')
            }
        }
      }
    const handleError = err => {
        setQrCodeError(err);
      }
    return (
        <ReactForm
            initialValues={initialValues}
            validate={NewLocationForm.validate}
            // eslint-disable-next-line react/jsx-sort-props
            onSubmit={(values) => NewLocationForm.onSubmit(values, setIsLoading, history)}
            render={({ handleSubmit, submitError }) => (
                <form onSubmit={handleSubmit} noValidate>
                    {/*submitError && (
                        <Alert variant='danger'>
                            {submitError}
                        </Alert>
                    )*/}
                    <Field name='location_id' type='hidden' defaultValue=''>
									{({ input, meta }) => (
										<input
                                            {...input}
										/>
									)}
                                </Field>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12}>
                                {
                                    qrCode  ?  <></> : (
                                        <QrReader
                                            delay={300}
                                            onError={handleError}
                                            onScan={handleScan}
                                            style={{ width: '100%' }}
                                            />)
                                }
								{qrCodeError && (<div className={classes.qrCodeError}>{qrCodeError}</div>)}
							</Grid>
                            { qrCode && 
                            (<>
                                <Grid item xs={12} sm={12} className={classes.qrCodeWrapper}>
                                    <Field name='id' type='text'>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                variant="outlined"
                                                className={classes.qrCodeTextField}
                                                id="id"
                                                label="QR код"
                                                name="id"
                                                error={meta.submitFailed}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={() => { setQrCode('');}}>
                                    <RefreshIcon className={classes.refreshIcon}/>
                              </IconButton>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Field name='location_id' type='text'>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                variant="outlined"
                                                fullWidth
                                                id="location_id"
                                                label="ID локации"
                                                name="location_id"
                                                error={meta.submitFailed}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Field name='comment' type='text'>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                autoFocus
                                                variant="outlined"
                                                fullWidth
                                                id="comment"
                                                label="Comment"
                                                name="comment"
                                                multiline
                                                rowsMax={4}
                                                error={meta.submitFailed}
                                            />
                                        )}
                                    </Field>
                                    
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={isLoading}
                                    >
                                        Создать локацию
                                        {isLoading && <CircularProgress size={30} />}
                                    </Button>
                                </Grid>
                            </>)
                            }
						</Grid>
					</form>

            )}
        />
    );
};


NewLocationForm.onSubmit = async (values, setIsLoading, history) => {
    try {
        console.log(values);
        setIsLoading(true);

        await firebase.qrCodes.createQrCode(values.id, values.location_id, values.comment);
        history.push(`/`);

        return null;
    } catch (error) {
        setIsLoading(false);

        logger.error('Error submitting form updateUser:', error);

        const errors = {};

        switch (error.code) {
            case AuthErrorCode.InvalidEmail:
            case AuthErrorCode.EmailAlreadyInUse:
                errors.email_address = error.message;
                break;

            case AuthErrorCode.WeakPassword:
                errors.password = error.message;
                break;

            default:
                errors[FORM_ERROR] = error.message;
        }

        return errors;
    }
};

NewLocationForm.validate = (values) => {
    const errors = {};


    if (!values.location_id)
        errors.location_id = 'ID локации';
    
    if (!values.id)
        errors.id = 'QR код';

    if (!values.comment)
        errors.comment = 'Обзательное поле';

    return errors;
};

export default NewLocationForm;
