import { FirestoreHelper } from '../common/firestore-helper';
import Lavizor from '../config/lavizor';
import ErrorCode from '../errors/error-code';
import LavizorError from '../errors/lavizor-error';

import UserManagementErrorCode from './error-code';
import { UserProfile } from './profiles';

/**
 * An object that allows access to user information and profiles.
 *
 */
export default class User {
    uid;
    name;
    phone_number;
    email_address;

    _shipping_address;

    constructor(user) {
        this.uid = user.uid;
        this.name = user.name || null;
        this.phone_number = user.phone_number || null;
        this.email_address = user.email_address || null;
    }

    /**
     * Access the user's profiles
     *
     */
    get profile() {
        return new UserProfile(this);
    }

    /**
     * Updates the name of the user
     *
     */
    async updateName(name) {
        const currentUser = this.getCurrentUser();

        const firestore = new FirestoreHelper(null);
        const docRef = Lavizor.getFirebase().firestore().collection('users').doc(currentUser.uid);

        try {
            await Lavizor.getFirebase().auth().currentUser?.updateProfile({
                displayName: name
            });

            await firestore.update(docRef, {
                name
            });
        } catch (error) {
            throw LavizorError.translateFirestoreError(error, new LavizorError('Failed to update the name', ErrorCode.UnknownError));
        }
    }


    /**
     * Updates the email address of the current user for both information and authentication purposes
     *
     */
    async updateEmailAddress(emailAddress) {
        const currentUser = this.getCurrentUser();

        const firestore = new FirestoreHelper(null);
        const docRef = Lavizor.getFirebase().firestore().collection('users').doc(currentUser.uid);

        try {
            await currentUser.updateEmail(emailAddress);

            await firestore.update(docRef, {
                email_address: emailAddress
            });
        } catch (error) {
            throw LavizorError.translateFirestoreError(error, new LavizorError('Failed to update the name', ErrorCode.UnknownError));
        }
    }

    /**
     * Updates the password of the currently signed-in user
     *
     * @throws {@link LavizorError} (code: {@link AuthErrorCode.WeakPassword}) Thrown if the password is not strong enough.
     * @throws {@link LavizorError} (code: {@link AuthErrorCode.RequiresRecentLogin}) Thrown if the user's last sign-in time does not meet the security
     * threshold.
     */
    async updatePassword(newPassword) {
        const currentUser = this.getCurrentUser();

        try {
            await currentUser.updatePassword(newPassword);
        } catch (error) {
            throw LavizorError.translateFirestoreError(error, new LavizorError('Failed to update the password', ErrorCode.UnknownError));
        }
    }

    /**
     * Converst the current user instance into a standard javascript object
     *
     */
    toJSON() {
        const result = {
            uid: this.uid,
            name: this.name,
            phone_number: this.phone_number,
            email_address: this.email_address
        };

        return result;
    }

    /**
     * Converts a standard javascript object into a User instance.
     *
     */
    static fromJSON(obj) {
        const result = new User(obj);

        return result;
    }

    getCurrentUser() {
        const currentUser = Lavizor.getFirebase().auth().currentUser;

        if (!currentUser || currentUser.uid !== this.uid)
            throw new LavizorError('User is not currently logged user', UserManagementErrorCode.NotCurrentlyLoggedUser);

        return currentUser;
    }
}