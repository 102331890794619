

import {LavizorError, ErrorCode} from '../sdk/errors';

export default class {
    constructor(firebase) {
        this.firebase = firebase;
    }

    subscribeToLinks = async (locationId, filters, orderBys, limit, successCallback, errorCallback) => {
        let query = this.firebase.db
        .collection('locations')
        .doc(locationId)
        .collection('links');

        if (filters) {
            filters.forEach((filter) => {
                query = query.where(...filter);
            });
        }

        if (orderBys && orderBys.length > 0) {
            orderBys.forEach((orderBy) => {
                query = query.orderBy(...orderBy);
            });
        }// else
         //   query = query.orderBy('created_at', 'asc');
        
        if (typeof limit === 'number')
            query = query.limit(limit);

        const unsubscribe = query.onSnapshot(
            (data) => {
                const result = data.docs.map((doc) => { return { ...doc.data(), id: doc.id }});
                if (successCallback)
                    successCallback(result || []);
            },
            (error) => {
                if (errorCallback) {
                    const generatedError = LavizorError.translateFirestoreError(
                        error,
                        new LavizorError('Failed to fetch links', ErrorCode.DatabaseOperationError, error)
                    );

                    errorCallback(generatedError);
                }
            }
        );

        return unsubscribe;
    }
}