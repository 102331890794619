import React from 'react';
import { Route as RouterRoute, Redirect } from 'react-router-dom';
import { useAuth } from '../core/hooks';


const redirectToLogin = (location) => <Redirect to={{ pathname: '/login', state: { from: location } }} />;

const Route = (props) => {
    const { onlyLoggedInUser, location, component, children } = props
    const { isLoading, data: user } = useAuth() || {};

   if (isLoading== null || isLoading === true)
        return <></>;
    if (onlyLoggedInUser && !user)
       return redirectToLogin(location);

    const getLayout = (component && component.getLayout) || ((page) => page);
    const Component = component;
    return (
        <RouterRoute {...props} component={null}>
            {Component ? (
                <>
                    {getLayout(<Component />)}
                </>
            ) : (
                <>
                    {children}
                </>
            )} 
        </RouterRoute>
    );
};

export default Route;
