/**
 * Provides access to a users's profile
 *
 */
export default class UserProfile {
    user = null;

    constructor(user) {
        this.user = user;
    }
}