import { LavizorError, ErrorCode } from '../errors';

/**
 * A helper function for use by all SDK modules. The primary purpose is to abstract away
 * the logic concerning database operations on whether they will be executed as part of a
 * transaction, batch, or independently. This will only be used internally in the SDK.
 * SDK consumers will not be using this.
 *
 * @template T The object type returned or being written
 */

export class FirestoreHelper {
    options = null;
    /**
     * Returns a new instance of FirestoreHelper with the correct options set
     *
     */
    constructor(options) {
        if (options)
            this.options = options;
    }

    withOptions(options) {
        const newOptions = this.options || {};

        if (newOptions?.batch && options?.batch)
            newOptions.batch = options.batch;

        if (newOptions?.transaction && options?.transaction)
            newOptions.transaction = options.transaction;

        return new FirestoreHelper(newOptions);
    }

    /**
     * Abstracts the firebase get operation
     */
    async get(docRef) {
        let result = null;

        if (this.options?.transaction)
            result = await this.options.transaction.get(docRef);
        else if (this.options?.batch)
            throw new LavizorError('Cannot perform read as part of a batch operation', ErrorCode.DatabaseOperationError);
        else
            result = await docRef.get();

        return result;
    }

    /**
     * Abstracts the firebase set operation
     */
    async set(docRef, data, options) {
        if (this.options?.transaction)
            await this.options.transaction.set(docRef, data, options || {});
        else if (this.options?.batch)
            await this.options.batch.set(docRef, data, options || {});
        else
            await docRef.set(data, options || {});
    }

    /**
     * Abstracts the firebase update operation
     */
    async update(docRef, data) {
        if (this.options?.transaction)
            await this.options.transaction.update(docRef, data);
        else if (this.options?.batch)
            await this.options.batch.update(docRef, data);
        else
            await docRef.update(data);
    }

    /**
     * Abstracts the firebase delete operation
     */
    async delete(docRef) {
        if (this.options?.transaction)
            await this.options.transaction.delete(docRef);
        else if (this.options?.batch)
            await this.options.batch.delete(docRef);
        else
            await docRef.delete();
    }

    applyListOptions(query, options) {
        let result = query;

        if (options.filter) {
            Object.entries(options.filter).forEach(([ key, value ]) => {
                result = result.where(key, '==', value);
            });
        }

        if (options.where && Array.isArray(options.where)) {
            options.where.forEach(([ key, operator, value ]) => {
                result = result.where(key, operator, value);
            });
        }

        if (options.orderBy) {
            Object.entries(options.orderBy).forEach(([ key, direction ]) => {
                result = result.orderBy(key, direction);
            });
        }

        // TODO: Skip doesn't work with a numeric value unless orderBy field has a numeric value. Instead, consider using firebase's
        //       cursor-based pagination.
        // if (options.skip)
        //     result = result.startAt(options.skip);

        if (typeof options.limit === 'number' && !Number.isNaN(options.limit))
            result = result.limit(options.limit);

        return result;
    }
}