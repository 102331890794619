// Material UI components
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import InstallApplication from '../components/install-application';

import LoginForm from '../components/forms/login';


const useStyles = makeStyles((theme) => ({
	paper: {
	  marginTop: theme.spacing(8),
	  display: 'flex',
	  flexDirection: 'column',
	  alignItems: 'center',
	},
	avatar: {
	  width: '50px',
	  marginBottom: '20px'
	},
	form: {
	  width: '100%', // Fix IE 11 issue.
	  marginTop: theme.spacing(1),
	},
	submit: {
	  margin: theme.spacing(3, 0, 2),
	},
  }));

const LoginPage = () => {
	const classes = useStyles();

	return (
	<Container component="main" maxWidth="xs">
		<CssBaseline />      
		<div className={classes.paper}>
			<img alt='' src="/lavizor512.png" className={classes.avatar}/>
			
			<LoginForm/>
		</div>
      <InstallApplication/>
	</Container>
);
			}

export default LoginPage;
