export default class {
    constructor(firebase) {
        this.firebase = firebase;
    }

    getTheme = async (locationId) => {
        if (locationId) {
            const baseTheme = (await this.firebase.db
                .collection('locations')
                .doc(locationId)
                .collection('public')
                .doc('theme')
                .get()).data();
            
            return baseTheme;
        }

        return Promise.reject(new Error('Missing parameter "locationId"'));
    }
}