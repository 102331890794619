import React, { useState, useEffect } from 'react';import { useHistory } from "react-router-dom";
import { useReviews, useAuth } from '../../core/hooks';
import TextField from '@material-ui/core/TextField';
import * as moment from 'moment';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({

    filterInput: {
        width: '100%',
        marginBottom: '20px',
    }
  }));

const Reviews = () => {
	const classes = useStyles();
    const history = useHistory();
	const [ allData, setAllData ] = useState();
	const [ filteredData, setFilteredData ] = useState();
    const { data: user } = useAuth() || {};
    const reviews = useReviews(user?.uid);
    useEffect(() => {
        if (reviews.data) {
            const data = reviews.data?.map((i, idx) => {
                return { 
                name:i.user.name, phone_number: i.user.number, link: i.link?.type ?? 'нет', 
                version:i.version?.name ?? '', 
                date:i.user.date, 
                created_at: moment(i.created_at.toDate()).format('HH:mm DD MMM yyyy'),
                answers: "\""+i.answers.map((j)=>j.answer).join()+"\"",
                id: i.id
            }});
            setAllData(data);
            setFilteredData(data)
        }
    }, [reviews.data]);
    
    const columns = [

        {
            headerName: 'Name',
            field: 'name',
            flex: 1,
            sortable: true,
            filterable: false,
        },
        {
            headerName: 'Дата',
            field: 'created_at',
            sortable: true,
            width: 150,
            type: 'date',
            filterable: false,
        }
    ];

    const handleChange = (event) => {    
        const query = event.target.value?.toLowerCase();
        let filtered = allData.filter((i)=>i.name?.toLowerCase().includes(query) || i.phone_number?.toLowerCase().includes(query));
        if (query == null || query === '') {
            filtered = allData;
        }
        setFilteredData(filtered);  }

    return (
        <div>
            <TextField 
                className={classes.filterInput} 
                id="standard-basic" 
                label="Введите имя для поиска"
                onChange={handleChange} />
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid 
                    rows={filteredData || []} 
                    columns={columns} 
                    pageSize={100}
                    disableMultipleSelection
                    onRowClick={(rowData) => {
                        history.push(`/reviews/${rowData.row.id}`);
                    }
                    } 
            />
            </div>
        </div>
        
)};

export default Reviews;
