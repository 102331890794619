
import Reviews from './reviews';

export default class {
    constructor(firebase) {
        this.firebase = firebase;

        this.Reviews = new Reviews(this.firebase);
    }


    get reviews() {
        return this.Reviews;
    }
}
