import React from 'react';
import InternalLayout from '../layouts/internal';
import NewLocationForm from '../components/forms/new-location';

const NewLocationPage = () => (
				<div>
					<NewLocationForm />
				</div>
);


NewLocationPage.getLayout = (page) => (
	<InternalLayout goBack>{page}</InternalLayout>
);

export default NewLocationPage;
