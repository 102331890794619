import { AuthErrorCode } from '../../core/firebase/sdk';
import { FORM_ERROR } from 'final-form';
import { useState } from 'react';
import { Form as ReactForm, Field } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import firebase from '../../core/firebase';
import { useReview, useAuth } from '../../core/hooks';
import { makeStyles } from '@material-ui/core/styles';


import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { logger } from '../../core/helpers';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

 
const average = list => list.reduce((prev, curr) => prev + curr) / list.length;

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '50%',
        flexShrink: 0,
      },
      secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
      },
    table: {
        width: '100%',
        border: 'lightgrey',
        borderCollapse: 'collapse'
    }
  }));

  
const ReviewEditForm = ({ userId, reviewId }) => {
    const classes = useStyles();
	const [ isLoading, setIsLoading ] = useState();
    const history = useHistory();
    const { data: user } = useAuth() || {};
    const { data: review } = useReview(user?.uid, reviewId) || {};

    const initialValues = {
        id: reviewId,
        comment: review?.comment,
        used: review?.used,
    };
    return (
        <ReactForm
            initialValues={initialValues}
            validate={ReviewEditForm.validate}
            // eslint-disable-next-line react/jsx-sort-props
            onSubmit={(values) => ReviewEditForm.onSubmit(user?.uid, values, setIsLoading, history)}
            render={({ handleSubmit, submitError }) => (
                <form onSubmit={handleSubmit} noValidate>
                    {/*submitError && (
                        <Alert variant='danger'>
                            {submitError}
                        </Alert>
                    )*/}
						<Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                            <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Пользователь</Typography>
          <Typography className={classes.secondaryHeading}>{review?.user?.name ?? 'Неизвестен'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <table cellspacing="0" border="1" className={classes.table}>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <strong>Имя</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {review?.user?.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Телефон</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <a href={`tel:${review?.user?.number}`}>{review?.user?.number}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Услуга</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {review?.user?.service}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Дата услуги</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {review?.user?.date}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
				
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Ответы</Typography>
          <Typography className={classes.secondaryHeading}>{review?.answers && <>Среднее: {average(review?.answers?.filter(a=>/^\d+$/.test(a.answer)).map(a=>parseInt(a.answer))).toFixed(1)}</>}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <table cellspacing="0" border="1" className={classes.table}>
                                    <tbody>
                                    {review?.answers.map((a) => (
                                            <>
                                                <tr>
                                                    <td><strong>{a.question}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{a.answer}</td>
                                                </tr>
                                            </>
                                    ))
                                    }
                                    </tbody>
                                </table>
					
        </AccordionDetails>
      </Accordion>
      {review?.link && 
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Ссылка</Typography>
          <Typography className={classes.secondaryHeading}>{review?.link?.type}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <table cellspacing="0" border="1" className={classes.table}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <strong>Тип</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            {review?.link?.type}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>URL</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <a href={review?.link?.url}>ссылка</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
				
        </AccordionDetails>
      </Accordion>}
      {review?.qr?.comment && 
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>QR код</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <table cellspacing="0" border="1" className={classes.table}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <strong>QR код комментарий</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            {review?.qr?.comment}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>QR код ID</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            {review?.qr?.id}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
						
          </Typography>
        </AccordionDetails>
      </Accordion>}
							</Grid>
							<Grid item xs={12} sm={12}>
								<Field name='comment' type='text'>
									{({ input, meta }) => (
										<TextField
											{...input}
											variant="outlined"
											fullWidth
											id="comment"
											label="Comment"
											name="comment"
                                            multiline
                                            rowsMax={4}
											error={meta.submitFailed}
										/>
									)}
                                </Field>
								
							</Grid>
                            <Grid item xs={12} sm={12}>
								<Field name='used' type='text'>
									{({ input, meta }) => (
										<TextField
                                            {...input}
                                            variant="outlined"
                                            fullWidth
                                            id="used"
                                            label="Бонус использован?"
                                            name="used"
                                            error={meta.submitFailed}
                                        />
									)}
                                </Field>
							</Grid>
                            <Grid item xs={12} sm={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={isLoading}
                                >
                                    Обновить
                                    {isLoading && <CircularProgress size={30} />}
                                </Button>
							</Grid>
						</Grid>
						
					</form>

            )}
        />
    );
};


ReviewEditForm.onSubmit = async (userId, values, setIsLoading, history) => {
    try {
        setIsLoading(true);
        
        await firebase.reports.reviews.updateReview(userId, values.id, { 
            comment: values.comment ?? '',
            used: values.used ?? ''
        });
        history.push(`/`);

        return null;
    } catch (error) {
        setIsLoading(false);

        logger.error('Error submitting form updateUser:', error);

        const errors = {};

        switch (error.code) {
            case AuthErrorCode.InvalidEmail:
            case AuthErrorCode.EmailAlreadyInUse:
                errors.email_address = error.message;
                break;

            case AuthErrorCode.WeakPassword:
                errors.password = error.message;
                break;

            default:
                errors[FORM_ERROR] = error.message;
        }

        return errors;
    }
};

ReviewEditForm.validate = (values) => {
    const errors = {};
    return errors;
};

export default ReviewEditForm;
