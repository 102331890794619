import AuthErrorCode from '../auth/error-code';

import ErrorCode from './error-code';

const ErrorCodeMap = {
    'failed-precondition': { message: 'Failed to perform fetch operation due to missing index', code: ErrorCode.DatabaseOperationError },
    'permission-denied': { message: 'Failed to perform database operation due to insufficient permissions', code: ErrorCode.DatabaseOperationError },
    'auth/invalid-verification-code': { message: 'The verification code is invalid', code: AuthErrorCode.InvalidVerificationCode},
    'auth/missing-verification-code': { message: 'The verification code is missing', code: AuthErrorCode.MissingVerificationCode},
    'auth/weak-password': { message: 'Password is too weak', code: AuthErrorCode.WeakPassword },
    'auth/invalid-email': { message: 'Invalid email', code: AuthErrorCode.InvalidEmail },
    'auth/user-not-found': { message: 'User is not found in the system', code: AuthErrorCode.UserNotFound },
    'auth/invalid-phone-number': { message: 'The phone number has an invalid format', code: AuthErrorCode.InvalidPhoneNumber },
    'auth/missing-phone-number': { message: 'Phone number is missing', code: AuthErrorCode.MissingPhoneNumber },
    'auth/quota-exceeded': { message: 'Server maintenance error', code: AuthErrorCode.QuotaExceeded },
    'auth/user-disabled': { message: 'User is disabled', code: AuthErrorCode.UserDisabled },
    'auth/operation-not-allowed': { message: 'Operation not allowed', code: AuthErrorCode.OperationNotAllowed },
    'auth/wrong-password': { message: 'Operation not allowed', code: AuthErrorCode.WrongPassword },
    'auth/email-already-in-use': { message: 'Email already in use', code: AuthErrorCode.EmailAlreadyInUse}
};

export default ErrorCodeMap;